const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Nama',
    dataIndex: 'name',
  },
  {
    title: 'Start Date',
    dataIndex: 'begin_date',
  },
  {
    title: 'End Date',
    dataIndex: 'end_date',
  },
  {
    title: 'Status',
    dataIndex: 'status_text',
  },
  {
    title: 'Updated Date',
    dataIndex: 'updated_at',
  },
  {
    title: 'Aksi',
    slots: { customRender: 'action' },
  },
]

export default acolumns
