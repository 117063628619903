export default {
  name: [
    {
      required: true,
      message: 'Nama tidak boleh kosong!',
    },
  ],
  begin_date: [
    {
      required: true,
      message: 'Tanggal Awal tidak boleh kosong!',
    },
  ],
  end_date: [
    {
      required: true,
      message: 'Tanggal Akhir tidak boleh kosong!',
    },
  ],
  status: [
    {
      required: true,
      message: 'Status tidak boleh kosong!',
    },
  ],
}