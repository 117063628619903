<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <!-- tombol  -->
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          type="primary"
          title="Tambah Data"
          @click="
            () => {
              formState = {}
              modal1Visible = true
              formState.title_state = 'Tambah Data'
              formState.state = 'new'
              formState.readOnly = false
            }
          "
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
    <!-- tabel -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body">
            <div class="table-responsive">
              <md-table
                :columns="columns"
                :data-source="data"
                row-key="id"
                :pagination="{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                  showTotal: (total, range) =>
                    `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                  total: meta.totalCount,
                  pageSize: meta.perPage,
                  current: meta.currentPage,
                }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                @change="handleTableChange"
                :loading="isFetching"
              >
                <template #no="{ index }">
                  <span>
                    {{ startRow + index }}
                  </span>
                </template>
                <template #action="{record}">
                  <div class="d-flex align-items-center ">
                    <a-tooltip title="Edit Data">
                      <a href="javascript: void(0);" class="btn btn-sm mx-1" @click="editData(record.id)">
                        <small>
                          <i class="fe fe-edit" />
                        </small>
                      </a>
                    </a-tooltip>
                    <a-tooltip title="Lihat Data">
                      <a href="javascript: void(0);" class="btn btn-sm mx-1" @click="lihatData(record.id)">
                        <small>
                          <i class="fe fe-eye" />
                        </small>
                      </a>
                    </a-tooltip>
                  </div>
                </template>
              </md-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <a-modal
      v-model:visible="modal1Visible"
      :title="formState.title_state"
      width="700px"
      :destroy-on-close="true"
      :mask-closable="false"
      @ok="modal1Visible = false"
    >
      <pre v-if="!true">{{ formState }}</pre>
      <a-form
        ref="formRef"
        :wrapper-col="{ span: 14 }"
        :scroll-to-first-error="true"
        :rules="rules"
        :model="formState"
        class="myform">
        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Nama"
              label-align="left"
              :label-col="{ sm: { span: 6 } }"
              :wrapper-col="{ sm: { span: 24 - 6 } }"
              has-feedback
              name="name"
            >
              <a-input
                width="100%"
                v-model:value="formState.name"
                :disabled="formState.readOnly"
                required
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Tanggal Awal"
              label-align="left"
              :label-col="{ sm: { span: 6 } }"
              :wrapper-col="{ sm: { span: 24 - 6 } }"
              name="begin_date"
              allow-clear
            >
              <a-date-picker
              width="100%"
              v-model:value="formState.begin_date"
              format="DD MMMM YYYY"
              placeholder="Start Date"
              :disabled="formState.readOnly"
              required
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Tanggal Akhir"
              label-align="left"
              :label-col="{ sm: { span: 6 } }"
              :wrapper-col="{ sm: { span: 24 - 6 } }"
              name="end_date"
              allow-clear
            >
              <a-date-picker
              width="100%"
              v-model:value="formState.end_date"
              format="DD MMMM YYYY"
              placeholder="End Date"
              :disabled="formState.readOnly"
              required
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Status"
              label-align="left"
              :label-col="{ sm: { span: 6 } }"
              :wrapper-col="{ sm: { span: 24 - 6 } }"
              name="status"
            >
              <a-radio-group
                :disabled="formState.readOnly"
                v-model:value="formState.status"
                required
              >
                <a-radio :value="true">Open</a-radio>
                <a-radio :value="false">Close</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <template #footer>
        <a-button
          :loading="isSubmit"
          key="submit"
          type="primary"
          @click="submit"
          :hidden="formState.readOnly"
          >Simpan</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { ref, watch, onMounted, reactive, provide, toRefs, computed, createVNode } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import moment, { Moment } from 'moment'
import rules from './rules'
import acolumns from './columns'

export default {
  components: {},
  setup() {
    const modalTitle = ref('')
    const modal1Visible = ref(false)
    const formRef = ref('')
    const formState = ref({
      begin_date: ref(Moment),
      end_date: ref(Moment),
    })
    const searchInput = ref(null)
    const columns = ref([])
    columns.value = acolumns
    const data = ref([])
    const dateFormat = 'YYYY-MM-DD';

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    const isSubmit = ref(false)
    const errorMessage = ref(null)
    const params = ref({})

    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/synchron-opens', {
          params: _params,
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    onMounted(() => {
      fetchData()
    })

    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })

    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const lihatData = id => {
      apiClient
        .get(`/api/synchron-opens/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal1Visible.value = true
          formState.value.title_state = 'Lihat Data'
          formState.value.readOnly = true
        })
        .catch(error => console.error({ failure: error }))
    }

    const editData = id => {
      apiClient
        .get(`/api/synchron-opens/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal1Visible.value = true
          formState.value.title_state = 'Edit Data'
          formState.value.state = 'edit'
          formState.value.readOnly = false
        })
        .catch(error => console.error({ failure: error }))
    }

    const search = () => {
      fetchData()
    }

    provide('formState', formState)

    const state = reactive({
      loading: false,
      isFetching: false,
    })

    const validate = () => {
      formRef.value
        .validate()
        .then(() => {
          console.log('values', formState, toRaw(formState))
        })
        .catch(error => {
          console.log('error', error)
        })
    }

    const submit = async () => {
      try {
        await formRef.value.validate()
      } catch (error) {
        console.log('submit', error)
        return
      }

      if (formState.value.state === 'new') {
        Modal.confirm({
          title: 'Konfirmasi Tambah Data',
          content: 'Apakah anda ingin melanjutkan tambah data ?',

          onOk() {
            isSubmit.value = true
            const form_data = new FormData()
            for (var key in formState.value) {
              if (key === 'status') {
                form_data.append(key, formState.value[key] ? 1 : 0)
              } else if (key === 'begin_date') {
                form_data.append(key, moment(formState.value[key]).format(dateFormat))
              } else if (key === 'end_date') {
                form_data.append(key, moment(formState.value[key]).format(dateFormat))
              } else {
                form_data.append(key, formState.value[key])
              }
            }
            apiClient.post('/api/synchron-opens', form_data)
                    .then(({ data }) => {
                      modal1Visible.value = false
                      fetchData()
                      message.success('Berhasil ditambah')
                    })
                    .catch(error => {
                      console.log(error.response.data)
                      if (error.response && error.response.data) {
                        message.error(error.response.data[0].message)
                      } else {
                        message.error('Gagal menyimpan!')
                      }
                    })
                    .finally(() => (isSubmit.value = false))
          },
          onCancel() {},
        })
      } else if (formState.value.state === 'edit') {
        Modal.confirm({
          title: 'Konfirmasi Edit Data',
          content: 'Apakah anda ingin melanjutkan edit data ?',
          onOk() {
            isSubmit.value = true
            const form_data = new FormData()
            for (var key in formState.value) {
              if (key === 'status') {
                form_data.append(key, formState.value[key] ? 1 : 0)
              } else if (key === 'begin_date') {
                form_data.append(key, moment(formState.value[key]).format(dateFormat))
              } else if (key === 'end_date') {
                form_data.append(key, moment(formState.value[key]).format(dateFormat))
              } else {
                form_data.append(key, formState.value[key] === null ? '' : formState.value[key])
              }
            }
            form_data.append('is_deleted', 0)
            apiClient.post('/api/synchron-opens/' + formState.value.id + '?_method=PUT', form_data)
                    .then(({ data }) => {
                      modal1Visible.value = false
                      fetchData()
                      message.success('Berhasil diupdate')
                    })
                    .catch(error => {
                      console.log(error.response.data)
                      if (error.response && error.response.data) {
                        message.error(error.response.data[0].message)
                      } else {
                        message.error('Gagal mengupdate!')
                      }
                    })
                    .finally(() => (isSubmit.value = false))
          },
          onCancel() {},
        })
      }
    }

    return {
      modalTitle,
      modal1Visible,
      searchInput,
      dateFormat,
      data,
      columns,
      meta,
      handleTableChange,
      startRow,
      formState,
      lihatData,
      editData,
      search,
      state,
      ...toRefs(state),
      submit,
      isSubmit,
      fetchData,
      errorMessage,
      rules,
      formRef,
      validate,
    }
  },
}
</script>
<style scoped>
.myform :deep(.ant-input-disabled),
.myform :deep(.ant-input[disabled]),
.myform
  :deep(.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector),
.myform :deep(.ant-radio-disabled + span) {
  /* color: #595c97 !important; */
  color: black;
  background-color: white;
  border: 0;
}
/* .ant-select-selection-placeholder */
.myform :deep(.ant-form-item) {
  margin-bottom: initial;
}
.myform :deep(.ant-select-disabled .ant-select-selection-placeholder),
.myform :deep(input.ant-input.ant-input-disabled::placeholder) {
  display: none;
  color: transparent;
}
.myform-vertical :deep(.ant-form-item-label > label) {
  font-size: 12px;
  margin: 0;
}
.myform-vertical :deep(.ant-form-item-label) {
  line-height: 1;
  padding: 0;
}
.hiden {
  visibility: visible;
}
</style>
